(function() {

  'use strict';
    angular.module('maideasy.directive', [])
    
    .directive('setClassWhenAtTop', function ($window) {
            var $win = angular.element($window); // wrap window object as jQuery object

            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var topClass = attrs.setClassWhenAtTop, // get CSS class from directive's attribute value
                        offsetTop = $("#sidebar-sticky").offset().top, // get element's top relative to the document
                        footerTop = $("#footer").offset().top,
                        sidebarHeight = $("#sidebar-sticky").outerHeight() - 672;

                    $win.on('scroll', function (e) {
                         if ($win.scrollTop() + sidebarHeight > footerTop)
                         {
                            // console.log(topClass)
                            if(topClass == 'fix-to-top')
                            {
                              element.removeClass(topClass);
                            }
                            element.addClass('fix-to-bottom');
                         }
                          else if($win.scrollTop() >= offsetTop) {
                            element.addClass(topClass);
                            element.removeClass('fix-to-bottom');
                        } else {
                            element.removeClass(topClass);
                            element.removeClass('fix-to-bottom');
                        }
                    });
                }
            };
        })

    .directive("formOnChange", function($parse, $interpolate){
      return {
        require: "form",
        link: function(scope, element, attrs, form){
          var cb = $parse(attrs.formOnChange);
          element.on("change", function(){
            cb(scope);
          });
        }
      };
    })
    .directive('passwordConfirm',function(){
        return {
            restrict: 'A',
            scope: {
              matchTarget: '=',
            },
            require: 'ngModel',
            link: function link(scope, elem, attrs, ctrl) {
              var validator = function (value) {
                ctrl.$setValidity('match', value === scope.matchTarget);
                return value;
              }
         
              ctrl.$parsers.unshift(validator);
              ctrl.$formatters.push(validator);
              
              // This is to force validator when the original password gets changed
              scope.$watch('matchTarget', function(newval, oldval) {
                validator(ctrl.$viewValue);
              });

            }
          };
    })

    

})();
