(function() {

'use strict';
    angular.module('maideasy.filter', [])


    .filter('dateFilter',function(){
        return function(date){
            if(date)
                return moment(date).format('DD-MM-YYYY');
            else{
                return 'Please select date'
            }
        }
    })
    .filter('specialFilter',function(){
        return function(req){
            if(req == 'window')
                return "Interior Window";
            else if (req == 'cupboard')
                return "Inside Cabinet";
            else if ( req == 'fridge')
                return "Inside Fridge";
            else if ( req == 'ironing')
                return "Ironing";
        }
    })

    .filter('timeFilter',function(){
        return function(time){
            if(time)
            {
                return moment(time).format('hh:mma');
            }
            else{
                return 'Please select time'
            }
        }
    })

    .filter('dateSimpleFilter',function(){
        return function(date){
            if(date)
                return moment(date).format('dddd');
            else{
                return 'Please select date'
            }
        }
    })
    .filter('myDateFormat', function myDateFormat(){
        return function(date){
            return moment(date).format('dddd , DD MMMM YYYY');
        }
    })
    .filter('dateFormat', function myDateFormat($filter){
      return function(text){
        var  tempdate = new Date(text.replace(/-/g,"/"));
        var str = $filter('date')(tempdate, "EEE, dd MMM yyyy @ h:mma");
        str.replace('12:00AM', '9:00AM, 8 Hours');
        return str;
      }
    })
    .filter('timeFormat', function myDateFormat(){
        return function(date){
            return moment(date).format('h:mma');
        }
    })

    .filter('simpleDateFormat', function shortDateFormat($filter){
      return function(text){
        var  tempdate = new Date(text.replace(/-/g,"/"));
        return $filter('date')(tempdate, "dd MMM yyyy");
      }
    })

    .filter("bookstatus",function(){
        return function(status){
            if(status == '150')
                return 'Contacting Cleaner';
            else if(status == '200')
                return 'Maid Assigned';
            else if(status == '300')
                return 'Canceled'
            else if(status == '302')
                return 'No Cleaner Available';
        }
    })

    .filter("postbookstatus",function(){
        return function(status){
            if(status == 150)
                return 'Contacting Cleaner';
            else if(status == 200)
                return 'Job Completed';
            else if(status == 300)
                return 'Canceled';
            else if(status == 302)
                return 'No Cleaner Available';
        }
    })

    .filter('jobType', function(){
      return function(jobType){
        if(jobType == 'basic')
            return 'Basic Home Cleaning';
        if(jobType == 'movein')
            return 'Move-in/Move-out CLEANING';
      }
    })

    .filter('statusFilter', function(){
      return function(booking){
        if(booking.status == 200)
            return 'Yay , ' +  booking.cleaner[0].callname +' has accepted your booking';
        else if(booking.status  == 150 || booking.status  == 100 || booking.status == 0)
            return 'Waiting for your cleaner to accept your job.';
        else if(booking.status == 302)
            return 'We have closed your booking due to no cleaner accept your job, We suggest you to try booking for another date';
        else if(booking.status == 300)
            return 'You have cancel this booking :(';

      }
    })

    .filter('jobtype', function(){
      return function(job_type){
        if(job_type == 'basic')
            return 'Basic home cleaning';
        if(job_type == 'movein')
            return 'Move-in/Move-out cleaning';
      }
    })

    .filter("repeatFormat",function(){
        return function(repeat){
            if(repeat == 'once')
                return 'One time';
            else if(repeat == 'weekly' || repeat == 'biweekly' || repeat == 'monthly')
                return repeat;
            else
                return 'One time';
            
        }
    })

    .filter('reschedule', function(){
      return function(status){
        if(status == 200 || status  == 150 || status  == 100 || status == 0)
            return 'Reschedule';
        else if(status == 302)
            return 'Book a new Date'
      }
    })

    ;


})();
