(function() {

	'use strict';
    angular.module('maideasy.services', [])
    
    .factory('AuthenticationService', function($injector, $window) {
        var auth = {
            isLogged: false,
            uuid: null,
            mobile: null,
        }

        return auth;
    })

    .factory('AuthHttpResponseInterceptor', function(AuthenticationService,$location,$q){
        return {
            request: function (config) {

                config.headers = config.headers || {};

                if (window.localStorage['secret']) {
                    config.headers['Authorization'] = window.localStorage['secret'];
                }

                return config;
            },

            // Set Authentication. isAuthenticated to true if 200 received
            response: function (response) {
                if (response != null && response.status == 200 && window.localStorage['secret'] && ! AuthenticationService.isLogged) {
                    AuthenticationService.isLogged = true;
                }

                return response;
            },

            // Revoke client authentication if 401 is received
            responseError: function(rejection) {

                console.log('responseError');

                if (rejection != null && rejection.status === 403 && (window.localStorage['secret'] || AuthenticationService.isLogged )) {

                    console.log('unauthorized access. log in again');

                    // Put these global
                    AuthenticationService.isLogged = false;

                    delete window.localStorage['secret'];

                    $location.path('/login');

                }else if (rejection != null && rejection.status === 501) {
                    $location.path('/login');
                    return $q.reject(rejection); 
                }

                return $q.reject(rejection); ;
            }

        };
    })
    .config(function ($httpProvider) {
        $httpProvider.interceptors.push('AuthHttpResponseInterceptor');
    })


    .service('URLManager', [function(){
            return {
                jsonToParams:function(json){
                    var str = [];
                    for(var j in json)
                        str.push(encodeURIComponent(j) + "=" + encodeURIComponent(json[j]));
                    return str.join("&");
                }
            }
        }])


    .service('$httpExtended', ['$http','URLManager',function($http,URLManager){
        return {
            post:function(url,data){
                return $http({
                            method:"POST",
                            url:url,
                            data: URLManager.jsonToParams(data),
                            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                        });
            }
        };
    }])

    .service('recurringService', function() {

      var recurring;

      var setRecurring = function(data) {
            console.log("setBooking",data);
            recurring = data;
            // Store the booking in local storage
            // window.localStorage['recurring'] = JSON.stringify(data);
      };

      var getRecurring= function(){
            console.log("getrecurring",recurring);
            // recurring = JSON.parse(localStorage.getItem('recurring'));
            return recurring;
      };

      return {
        setRecurring: setRecurring,
        getRecurring: getRecurring
      };

    })


    //copy from customer app
    .service('BookingsService', function() {

        function isBeforeSession(booking) {
            return moment().isBefore(booking.date);
        }

        function isInSession(booking) {
            var now = moment();
            return now.isSame(booking.date, 'day');
        }

        function isAfterSession(booking) {
            return moment().isAfter(moment(booking.date), 'day');
        }

        // confirm today and future sessions
        function isScheduled(booking) {
            var now = moment();
            return (booking.status == 200 && (now.isBefore(booking.date) || now.isSame(booking.date, 'day')) );
        }

        return {
            isBeforeSession: isBeforeSession,
            isInSession: isInSession,
            isAfterSession: isAfterSession,
            isScheduled: isScheduled,
        }

    })    



})();
