(function() {

	'use strict';
    angular.module('maideasy.dashboard', [
        'ngclipboard',
    	'ui.router',
    	'maideasy.directive',
    	'maideasy.services',
        'maideasy.filter',
        'ui.bootstrap.modal',
        'ngFlash'
    	])

        .constant('SERVER_CONFIG', {

            live_path: 'https://core.maideasy.my/api/v1',
            // live_path: 'http://core.maideasy.dev/api/v1',

            //live_path: 'http://localhost:8080/api/v1',
        })

        .run(function ($rootScope) {
             $('.navmenu').offcanvas({'toggle': false});
    
            $rootScope.$on('$stateChangeStart', function (event, next, toparams , current) {
                $('.navmenu').offcanvas('hide');
            });
        })

        .service('FacebookService', function () {
            this.app_id = '1775754435979423';
            this.hasInit = false;
            this.init = function () {
                if(this.hasInit){
                    return;
                }

                this.hasInit = true;
                  window.fbAsyncInit = function() {
                    FB.init({
                      appId      : '1775754435979423',
                      xfbml      : true,
                      version    : 'v2.8'
                    });
                    FB.AppEvents.logPageView();
                  };

                  (function(d, s, id){
                     var js, fjs = d.getElementsByTagName(s)[0];
                     if (d.getElementById(id)) {return;}
                     js = d.createElement(s); js.id = id;
                     js.src = "//connect.facebook.net/en_US/sdk.js";
                     fjs.parentNode.insertBefore(js, fjs);
                   }(document, 'script', 'facebook-jssdk'));
            };
        })

    	.config(function($stateProvider,$urlRouterProvider) {
    		$urlRouterProvider.otherwise('/dashboard');

    		$stateProvider
                .state('token',{
                    url : '/dashboard/?secret',
                    templateUrl : "/maideasyPro/templates/user-dashboard/index.html",
                    controller : "tokenController"
                })
                .state('dashboard',{
                    url : '/dashboard',
                    templateUrl : "/maideasyPro/templates/user-dashboard/index.html",
                    controller : "dashboardController"
                })
                .state('creditcard',{
                    url : '/creditcard',
                    templateUrl : "/maideasyPro/templates/user-dashboard/credit-card.html",
                    controller : "creditCardController"
                })
                .state('login',{
                    url : '/login',
                    templateUrl : "/maideasyPro/templates/user-dashboard/login.html",
                    controller : "loginController"
                })
                .state('bookings',{
                    url : '/bookings',
                    templateUrl : "/maideasyPro/templates/bookings-status/index.html",
                    controller : "bookingsListController"
                })
                .state('history',{
                    url : '/history',
                    templateUrl : "/maideasyPro/templates/bookings-history/index.html",
                    controller : "bookingsHistoryController"
                })
                .state('referrals',{
                    url : '/referrals',
                    templateUrl : "/maideasyPro/templates/referrals/index.html",
                    controller : "referralsController"
                })
                .state('password',{
                    url : '/forget-password',
                    templateUrl : "/maideasyPro/templates/user-dashboard/password.html",
                    controller : "passwordController"
                })

    	})

    	.controller('tokenController', function($scope,$stateParams,$state){

    		$scope.secret = $stateParams.secret;

            window.localStorage['secret'] = $scope.secret;

            $state.go("dashboard");
    	})

        .controller('navbarController', function($scope){

            $scope.goHomePage = function(){

                window.location.href = "/";
            }

            $scope.logout = function(){
                delete window.localStorage['secret'];
                window.location.href = "/";
            }

            $scope.login = function(){
                $state.go("login");
            }
            
        })

    	.controller('dashboardController', function($scope,$stateParams,$http,$timeout,$state,$location,AuthenticationService,SERVER_CONFIG,$httpExtended,Flash){

            $scope.token = $stateParams.token;

            $scope.secret = $stateParams.secret;

            $scope.auth = AuthenticationService.isLogged;

            $scope.navTitle = 'My Account';

            $scope.referralSent = '';

            if($scope.auth)
            {
                //get user data
                $http.get(SERVER_CONFIG.live_path + "/users/self").
                then(function(data){
                    $scope.profile = data.data.user;

                    $scope.getCreditCard();
                })
                
            }else{

                // not login redirect to login page
                $state.go("login");
            }

            $scope.submitProfile = function(){
                $scope.profileSubmitted = true;
            }

            $scope.getCreditCard = function(){

                //get credit card
                $http.get(SERVER_CONFIG.live_path + "/payments/" + $scope.profile.id ).
                then(function(data){

                    $scope.creditcards = data.data.savedCards;

                    if($scope.creditcards == "")
                    {
                        $scope.emptyCredit = "No saved credit card";
                        var message = '<strong> No saved credit card.</strong>';
                        var id = Flash.create('danger', message );
                    }

                })
            }

            $scope.updateProfile = function(profile){

                $http.put(SERVER_CONFIG.live_path + "/users/self",profile).
                then(function(data){
                    $scope.profile = data.data.user;
                    $scope.successMessage = "Profile Updated";
                    var message = '<strong>Profile Updated</strong>';
                    var id = Flash.create('success', message);
                })
            }

            $scope.updateCreditCard = function(){

                $state.go('creditcard');
            }

            $scope.updatePassword = function(profile){

                $http.post(SERVER_CONFIG.live_path + '/users/self/password',profile)
                .success(function(data){

                    $scope.passwordUpdated = "Password updated";

                    $scope.updateSuccess = true;

                    var message = '<strong> Password successfully updated.</strong>';
                    var id = Flash.create('success', message);

                    window.localStorage['secret'] = data.secret;

                    $('#password-btn').prop('disabled', true); // disable submit button

                })
            }

            $scope.getMessage =function() {
              return $scope.updateSuccess;
            }

            $scope.referFriend = function( invite ){
                $scope.referralSent = '';

                $http.post(SERVER_CONFIG.live_path + '/referrals/self/invite',invite)
                .finally(function(data){
                    $scope.referralSent = "Invite sent!";
                })
            }
            
        })

        .controller('creditCardController',function($state,$stateParams,$scope,$http,SERVER_CONFIG,AuthenticationService,$httpExtended){

            $scope.braintreeMessage = 'Update';

            $scope.creditcard = {};

            $scope.auth = AuthenticationService.isLogged;

            $scope.navTitle = 'Update payment';

            if($scope.auth)
            {
                //get user data
                $http.get(SERVER_CONFIG.live_path + "/users/self").
                then(function(data){
                    $scope.profile = data.data.user;
                })
            }else{

                // not login redirect to login page
                // window.location.href = "/";
                $state.go("login");
            }

            braintree.setup(window.clientToken, "dropin", {
                container: "dropin-container",
                paymentMethodNonceReceived: function(event, nonce) {

                    $scope.creditcard.nonce = nonce;

                    $scope.braintreeMessage = 'Processing ...';
                    $('#cardSubmit').prop('disabled', true); // disable submit button

                    //@todo change url to new
                    var payurl = '/payments/'+$scope.profile.id+'/update';

                    //@todo update api url
                    $http.post(SERVER_CONFIG.live_path+payurl,$scope.creditcard)
                        .then(function(data, status) {
                            // return payment_status
                            if (data.data.response.success) {
                                $scope.goDashboard();
                            } else {
                                $scope.braintreeMessage = 'Update failed . Try  again';
                                $('#cardSubmit').prop('disabled', false); // enable submit button
                            }

                        })
                
                },
                onReady: function() {
                    console.log('Braintree is ready');
                    // $rootScope.resizeSidebar();
                    $('#cardSubmit').prop('disabled', false); // enable submit button
                }

            });

            $scope.goDashboard = function(){
                $state.go('dashboard');
            }
        })


        .controller('loginController',  function($http,$scope,SERVER_CONFIG,$state,AuthenticationService){

            $scope.auth = AuthenticationService.isLogged;

            if($scope.auth){
                $state.go('dashboard');
            }

            $scope.userLogin = function(user)
            {   
                //@todo change url api
                $http.post(SERVER_CONFIG.live_path + "/users/login",user)
                .then(function(response){

                    window.localStorage['secret'] = response.data.secret;
                    $state.go('dashboard');
        
                }
                ,function(data){
                    $scope.loginError = "Invalid user name or password";
                })
            }
            
        })

        .controller('bookingsListController', function($scope,AuthenticationService,$http,SERVER_CONFIG,BookingsService, $modal){

            $scope.auth = AuthenticationService.isLogged;
            $scope.navTitle = 'My Bookings';
            

            $scope.getBookings = function(){

                $scope.loading = true;
                //get user bookings
                $http.get(SERVER_CONFIG.live_path + '/users/self/future/bookings' ,{cache:true})
                .success(function(data){
                    
                    $scope.loading = false;
                    $scope.bookings = data.bookings;

                    //$scope.bookings = [];
                    // for (var i = 0; i < 50; i++) 
                    // {
                    //         $scope.bookings.push(i);
                    // }
                    
                    
                })
                .error(function(data){
                    console.log("erorr");
                    $scope.loading = false;
                })
            }

            if(!$scope.auth)
            {
                // not login redirect to login page
                $state.go("login");  
            
            }else{

                $scope.getBookings(); 
                
            }

            $scope.open = function (booking) {

                var modalInstance = $modal.open({
                  templateUrl: 'maideasyPro/templates/modal/book-status.html',
                  controller: 'SingleBookingStatusCtrl',
                  size: 'sm',
                  resolve: {
                    booking: function () {
                      return booking;
                    }
                  }
                });
            };
 
        })

        .controller('SingleBookingStatusCtrl', function($scope,$modalInstance,booking,$http,SERVER_CONFIG,$modal,Flash){

            $scope.singleBooking = booking;

            $scope.dates = [];              

            for(var i = 1 ; i < 14 ; i++)
            {   
                var d = moment().add(i,'days').format("dddd, MMMM DD");
                var v = moment().add(i,'days').format("YYYY-MM-DD");

                $scope.dates.push({value:v, name:d});
            }


            $scope.confirmCancelBooking  = function(booking){
                $http.put(SERVER_CONFIG.live_path + "/bookings/" + booking.token + '/cancel')
                .then(function(data){
                    $modalInstance.close();
                })
            }

            $scope.cancelBooking = function(){
                $scope.cancel = true;
                $scope.update = true;
            }

            $scope.cancelCancelBooking = function(){
                $scope.cancel = false;
                $scope.update = false;
            }

            $scope.ok = function () {
                $modalInstance.close();
            };

            $scope.exit = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.rescheduleBooking = function () {
                $scope.reschedule = true;
                $scope.update = true;
            };

            $scope.confirmChangeDate = function(booking){

                booking.date = booking.dateChosen;

                $http.put(SERVER_CONFIG.live_path + "/bookings/" + booking.token + '/reschedule',booking)
                .then(function(data){
                    booking.date = booking.dateChosen;
                    booking.status = 150;
                    $modalInstance.close();

                    //@todo show flash message of date successfully changes
                    var message = '<strong>Change date successfully.</strong>';
                    var id = Flash.create('success', message);

                    
                })
            }

            $scope.cancelChangeDate = function(){
                $scope.reschedule = false;
                $scope.update = false;
            }
            
        })

        .controller('bookingsHistoryController', function($scope,AuthenticationService,$http,SERVER_CONFIG,BookingsService,$modal){

            $scope.auth = AuthenticationService.isLogged;
            $scope.navTitle = 'My Bookings';

            $scope.pastBookingsEmpty = false;
            

            $scope.getPastBookings = function(profile){
                $scope.loading = true;
                //get user past bookings
                $http.get(SERVER_CONFIG.live_path + '/users/self/past/bookings' ,{cache:true})
                .success(function(data){
                    $scope.loading = false;
                    $scope.pastBookings = data.bookings;
                    console.log($scope.pastBookings.length);
                    if($scope.pastBookings.length == 0)
                    {
                        $scope.pastBookingsEmpty = true;
                    }

                })
                .error(function(data){
                    console.log("erorr");
                    $scope.loading = false;
                })
            }

            if(!$scope.auth)
            {
                // not login redirect to login page
                // window.location.href = "/";
                $state.go("login");
                //get user data
                
            }else{
                
                $scope.getPastBookings();

            }

            $scope.open = function (booking) {

                var modalInstance = $modal.open({
                  templateUrl: 'maideasyPro/templates/modal/book-history.html',
                  controller: 'SingleBookingStatusCtrl',
                  size: 'sm',
                  resolve: {
                    booking: function () {
                      return booking;
                    }
                  }
                });
            };

 
        })

        .controller('referralsController', function($scope,AuthenticationService,$http,SERVER_CONFIG,BookingsService, FacebookService,$state){

            $scope.auth = AuthenticationService.isLogged;
            $scope.navTitle = 'Referrals';
            $scope.inviteSuccessMessage = '';
            $scope.referralCode = '';

            $scope.inviteLink = '';
            $scope.referralInfo = {
                total_share: 0,
                click_total: 0,
                succesful_referral: 0,
                credit_total: 0,
                credit_remains: 0
            }

            // pull out record from the core server
            $scope.getReferrals = function(){

                $http.get(SERVER_CONFIG.live_path + '/referrals/self' ,{cache:true})
                .then(function(response){
                    // console.log(response.data);
                    $scope.referralCode = response.data.referral_code;
                    $scope.inviteLink = 'https://maideasy.my/?link=' + response.data.referral_code;
                    $scope.referralInfo =  response.data;
                    // {
                    //     // total_share: 50,
                    //     total_click: data.click_total,
                    //     successful_referral: data.successful_referral,
                    //     credit_total: data.credit_total,
                    //     // credit_remains: 80
                    // }
                },function(data){
                    console.log(data);
                })                 
            }

            // send mass invite
            $scope.inviteFriends = function( invite ){
                console.log(invite);
                $scope.inviteSuccessMessage = '';

                $('input[placeholder="Email"]').attr('disabled', 'disabled');
                $http.post(SERVER_CONFIG.live_path + '/referrals/self/invite', invite )
                .success(function(data){
                    console.log(data);
                    // cleaner all the textarea
                    // this shouuld go to success() later
                    invite.email1 = '';
                    invite.email2 = '';
                    invite.email3 = '';
                    $scope.inviteSuccessMessage = 'Invite sent!';
                })
                .error(function(data){
                    console.log("error");
                })
                .finally( function(){
                    $('input[placeholder="Email"]').removeAttr('disabled');
                    
                })

            }

            $scope.shareToMessanger = function(){
                FB.ui({
                    method: 'send',
                    display: 'popup',
                    link: 'https://www.maideasy.my/?link='+ $scope.referralCode,   
                  }, function(response){});
            }

            $scope.shareToFacebook = function(){
                FB.ui({
                    method: 'share',
                    display: 'popup',
                    href: 'https://www.maideasy.my/?link=' + $scope.referralCode,   
                  }, function(response){});
            }

            $scope.copyLinkToClipboard = function() {
                // todo: copy link to clipboard, use Clipboard
                new Clipboard('.btn', {
                    text: function(trigger) {
                        return trigger.getAttribute('aria-label');
                    }
                });

            }

            if(!$scope.auth)
            {
                // not login redirect to login page
                // window.location.href = "/";
                $state.go("login");
                //get user data
                
            }else{
                
                $scope.getReferrals();
            }

            FacebookService.init();

 
        })

        .controller('passwordController', function($scope,$http,SERVER_CONFIG){

            $scope.sendEmail = function(email){
                $http.post(SERVER_CONFIG.live_path + '/forgot-password',{"email" : email})
                .then(function(data){
                    $scope.feedbackMsg = "Check your inbox for a link to reset your password";
                },function(data){
                    $scope.feedbackMsg = "Error please try again";
                }) 
            }
            
        })

})();
